import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "54px 0 30px 0",
	"quarkly-title": "Footer",
	"lg-padding": "30px 0 20px 0"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "25%",
			"md-width": "50%",
			"sm-margin": "0px 0px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-justify-content": "center",
			"sm-padding": "16px 16px 16px 0px"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"height": "auto",
			"max-width": "100%",
			"src": "https://uploads.quarkly.io/6744384ce778710018d530d5/images/images.png?v=2024-11-25T15:22:07.502Z",
			"width": "120px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "25%",
			"flex-direction": "column",
			"md-width": "50%",
			"sm-padding": "16px 16px 16px 0px",
			"empty-border-style": "solid",
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"empty-min-width": "64px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Навігація"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "Домашня сторінка"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/pro-nas",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"children": "Про нас"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/kontakti",
			"text-decoration-line": "initial",
			"children": "Контакти"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"empty-border-width": "1px",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"md-width": "50%",
			"sm-padding": "16px 16px 16px 0px",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"width": "25%",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Інше"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "/politika-konfidencijnosti",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "Політика Конфіденційності"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"empty-border-color": "LightGray",
			"padding": "16px 16px 16px 16px",
			"display": "flex",
			"flex-direction": "column",
			"sm-padding": "16px 16px 16px 0px",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"width": "25%",
			"md-width": "50%"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--headline4",
			"color": "--dark",
			"lg-font": "600 24px/1.3 \"Manrope\", sans-serif",
			"children": "Контакти"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "0938844092",
			"children": "0938844092"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 16px 0px",
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "mailto:mail@gmail.coіsupport@settrailblaze24.comm",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"children": "info@skillenhance24.live.com"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"lg-font": "normal 400 16px/1.5 \"Manrope\", sans-serif",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"margin": "0px 0px 16px 0px",
			"children": <>
				вулиця Зелена, 109,
				<br />
				{" "}офіс 444, Львів
			</>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Image {...override("image")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Link {...override("link")} />
			<Link {...override("link1")} />
			<Link {...override("link2")} />
		</Box>
		<Box {...override("box2")}>
			<Text {...override("text1")} />
			<Link {...override("link3")} />
		</Box>
		<Box {...override("box3")}>
			<Text {...override("text2")} />
			<Link {...override("link4")} />
			<Link {...override("link5")} />
			<Link {...override("link6")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;