import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"padding": "18px 0 18px 0",
	"quarkly-title": "Header",
	"box-shadow": "--xl"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline4",
			"text-transform": "uppercase",
			"children": "skillenhance"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"width": "50%"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"lg-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"size": "32px"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"md-flex-direction": "column",
			"justify-content": "flex-end"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"lg-margin": "0px 6px 0px 6px",
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"md-margin": "0px 18px 10px 18px",
			"href": "/index",
			"children": "Домашня сторінка"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"md-margin": "0px 18px 10px 18px",
			"lg-margin": "0px 6px 0px 6px",
			"text-decoration-line": "initial",
			"color": "--dark",
			"href": "/pro-nas",
			"children": "Про нас"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"color": "--dark",
			"font": "--lead",
			"padding": "6px 6px 6px 6px",
			"margin": "0px 18px 0px 18px",
			"lg-margin": "0px 6px 0px 6px",
			"href": "/kontakti",
			"children": "Контакти"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "/kontakti",
			"text-decoration-line": "initial",
			"color": "--light",
			"font": "--lead",
			"hover-opacity": "0.9",
			"background": "--color-green",
			"border-radius": "8px",
			"padding": "11px 24px 12px 24px",
			"children": "Зателефонувати",
			"display": "none"
		}
	}
};

const Header2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="space-around" flex-direction="row" align-items="center" />
		<Text {...override("text")} />
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Box {...override("box")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		<Link {...override("link3")} />
		{children}
	</Section>;
};

Object.assign(Header2, { ...Section,
	defaultProps,
	overrides
});
export default Header2;